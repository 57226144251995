html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  height: 100%; /* Ensure body takes full height */
  min-width: 350px;
  min-height: 600px;
}

.home {
  animation: fadeIn 0.4s ease-in;
  width: 100%;
  height: 100vh; /* Ensure it takes full viewport height */
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Lexend, sans-serif;
}

.headerWrapper {
  background-color: rgba(18, 22, 25, 0.95); /* Darker grey with 90% opacity */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text */
  width: auto;
  margin: 0; /* Remove left margin */
}

.headerContainer p {
  padding: 0;
  font-size: 24px; /* Default font size */
  font-weight: lighter;
  color: rgb(255, 255, 255);
  margin-bottom: 10px; /* Reduce margin between paragraphs */
}

.headerContainer .verse {
  width: 100%; /* Ensure it takes full width of the container */
  font-size: 20px; /* Default font size */
  margin-top: 0; /* Remove top margin */
  word-wrap: break-word; /* Ensure long words break to the next line */
  overflow-wrap: break-word; /* Ensure long words break to the next line */
}

.buttonContainer {
  display: flex;
  justify-content: center; /* Center buttons */
  gap: 40px; /* Increase space between buttons */
  width: 70%; /* Make the container take up the full width */
  padding: 5px 0; /* Add some padding for spacing */
}

.buttonContainer button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #172f49; /* Change button color */
  height: 50px;
  width: 200px; /* Default width */
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 0; /* Remove margin between buttons */
}

.headerContainer button:hover {
  background-color: #0056b3; /* Change hover color */
  transition: 0.2s ease-in;
}

/* Media Queries for Different Screen Sizes */

/* Larger than 1700px */
@media only screen and (min-width: 1700px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 64px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 32px;
  }
  .headerContainer .verse {
    font-size: 28px;
  }
  .buttonContainer button {
    width: 220px; /* Larger button width */
    margin-top: 10px;
  }
}

/* 1700px to 1400px */
@media only screen and (max-width: 1700px) and (min-width: 1400px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 48px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 28px;
  }
  .headerContainer .verse {
    font-size: 24px;
  }
  .buttonContainer button {
    width: 200px; /* Default button width */
    margin-top: 10px;
  }
}

/* 1400px to 1100px */
@media only screen and (max-width: 1400px) and (min-width: 1100px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 48px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 24px;
  }
  .headerContainer .verse {
    font-size: 20px;
  }
  .buttonContainer button {
    width: 180px; /* Smaller button width */
    margin-top: 10px;
  }
}

/* 1100px to 700px */
@media only screen and (max-width: 1100px) and (min-width: 700px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 20px;
  }
  .headerContainer .verse {
    font-size: 18px;
  }
  .buttonContainer button {
    width: 160px; /* Smaller button width */
    margin-top: 10px;
  }
}

/* 700px and smaller */
@media only screen and (max-width: 700px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 16px;
  }
  .headerContainer .verse {
    font-size: 14px;
    width: 100%; /* Ensure it takes full width of the container */
    word-wrap: break-word; /* Ensure long words break to the next line */
    overflow-wrap: break-word; /* Ensure long words break to the next line */
  }

  .buttonContainer {
    width: 100%; /* Make the container take up the full width */
    padding: 5px 0; /* Add some padding for spacing */
  }

  .buttonContainer button {
    width: 100px; /* Smaller width */
    height: 30px; /* Smaller height */
    font-size: 14px; /* Smaller font size */
    margin-top: 10px;
  }
}
