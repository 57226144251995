.navbar {
  width: 100%;
  height: 100px;
  background-color: #121619;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo img {
  width: 100px; /* adjust as needed */
  height: 100px; /* adjust as needed */
}

.nav-links li {
  margin: 0 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
}

.nav-links a:hover {
  color: #ccc; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
  text-decoration: underline; /* Add underline on hover */
}

/* Media Queries for Smaller Screens */
@media (max-width: 580px) {
  .logo {
    display: none; /* Hide the logo on smaller screens */
  }

  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .nav-links li {
    margin: 0 10px; /* Reduce margin between links */
  }

  .nav-links a {
    font-size: 16px; /* Reduce font size */
  }
}
