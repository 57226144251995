.writings {
  width: 100%;
  height: 100vh; /* Ensure it takes full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Ensure background image covers the entire area */
  font-family: Lexend, sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  width: 100%;
  height: calc(100vh - 60px); /* Adjust height to account for navbar height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.translucentBox {
  background-color: rgba(18, 22, 25, 0.95); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
  text-align: center;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  position: relative; /* Ensure the back button is positioned relative to this box */
}

.fade-in {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.fade-out {
  opacity: 1; /* Keep opacity 1 to ensure the box is visible */
  visibility: visible; /* Keep visibility visible */
  pointer-events: auto; /* Ensure it is interactable */
}

.writingTitle {
  font-size: 48px;
  color: white;
  margin-bottom: 10px;
}

.subtitle {
  margin-top: 20px;
}

.categoryTitle {
  font-size: 32px;
  color: white;
  margin: 0;
}

.separator {
  border: 1px solid white;
  margin: 20px 0;
}

.categories {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow categories to wrap to the next line */
  margin-top: 20px;
}

.category {
  flex: 1 1 45%; /* Allow categories to take up 45% of the width and wrap */
  margin: 10px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
}

.category:hover {
  color: #ccc; /* Change color on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.categoryName {
  font-size: 24px;
  color: white;
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Add transition for hover effect */
}

.categoryName:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly darken on hover */
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.backButton {
  font-size: 24px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px; /* Adjust the top position to be within the box */
  left: 10px; /* Adjust the left position to be within the box */
}

.header .writingTitle {
  font-size: 36px; /* Increase the font size of the title */
  color: white;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.writingsList {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.writingModal {
  background-color: rgba(255, 255, 255, 0.9); /* Light background for modal */
  padding: 10px; /* Increase the padding */
  border-radius: 10px;
  min-width: 80px; /* Set a minimum width */
  height: auto; /* Allow height to adjust based on content */
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
  cursor: pointer;
  white-space: nowrap; /* Prevent text from wrapping */
}

.writingModal:hover {
  background-color: rgba(200, 200, 200, 0.9); /* Darken on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.writingModal .writingTitle {
  font-size: 14px; /* Adjust the font size inside the modal */
  color: #333;
  margin: 0; /* Remove margin */
}

/* Media Queries for Different Screen Sizes */

/* Larger than 1700px */
@media only screen and (min-width: 1700px) {
  .header .writingTitle {
    font-size: 64px;
  }
  .categoryTitle {
    font-size: 48px;
  }
  .categoryName {
    font-size: 32px;
  }
  .backButton {
    font-size: 32px;
  }
}

/* 1700px to 1400px */
@media only screen and (max-width: 1700px) and (min-width: 1400px) {
  .header .writingTitle {
    font-size: 56px;
  }
  .categoryTitle {
    font-size: 40px;
  }
  .categoryName {
    font-size: 28px;
  }
  .backButton {
    font-size: 28px;
  }
}

/* 1400px to 1100px */
@media only screen and (max-width: 1400px) and (min-width: 1100px) {
  .header .writingTitle {
    font-size: 48px;
  }
  .categoryTitle {
    font-size: 36px;
  }
  .categoryName {
    font-size: 24px;
  }
  .backButton {
    font-size: 24px;
  }
}

/* 1100px to 700px */
@media only screen and (max-width: 1100px) and (min-width: 700px) {
  .header .writingTitle {
    font-size: 40px;
  }
  .categoryTitle {
    font-size: 32px;
  }
  .categoryName {
    font-size: 20px;
  }
  .backButton {
    font-size: 20px;
  }
}

/* 700px and smaller */
@media only screen and (max-width: 700px) {
  .header .writingTitle {
    font-size: 32px;
  }
  .categoryTitle {
    font-size: 28px;
  }
  .categoryName {
    font-size: 18px;
  }
  .backButton {
    font-size: 18px;
  }
}

@media only screen and (max-width: 580px) {
  .header .writingTitle {
    font-size: 28px; /* Reduce font size for smaller screens */
  }
  .backButton {
    display: none; /* Hide the back button on smaller screens */
  }
}

/* Media query for screens smaller than 480px */
@media only screen and (max-width: 480px) {
  .writingModal .writingTitle {
    font-size: 10px; /* Adjust the font size for section titles */
  }
}
