html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Lexend, sans-serif;
  background: url("../src/assets/small_river_NW.jpg") no-repeat center center
    fixed;
  background-size: cover;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgb(24, 23, 23);
  color: white;
  padding: 10px 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo img {
  height: 100;
  width: auto;
}

.navbar .nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar .nav-links li {
  margin: 0 15px;
}

.navbar .nav-links li a {
  color: white;
  text-decoration: none;
}

section {
  padding: 60px 20px;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0);
}

#home {
  background-color: transparent; /* Transparent to show the fixed background */
}

#writings {
  background-color: transparent; /* Transparent to show the fixed background */
}

#about {
  background-color: transparent; /* Transparent to show the fixed background */
}
