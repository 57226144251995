body {
  overflow: hidden; /* Prevent page scrolling */
}

.writing-detail {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.writing-detail-content {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 30px;
  border-radius: 10px;
  max-width: 1000px; /* Increase the width */
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: calc(
    100vh - 40px - 60px
  ); /* Ensure the box takes up the full height minus padding and navbar height */
  margin-top: 60px; /* Add top margin to account for the navbar height */
  font-family: "Verdana", sans-serif; /* Set a more readable font */
  font-size: 18px; /* Increase font size */
  line-height: 1.6; /* Increase line spacing */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center; /* Ensure the title is centered */
}

.header h1 {
  margin-top: 100px; /* Move the title down slightly */
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.back-button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
  position: absolute;
  top: 60px;
  left: 10px;
}

.back-button:hover {
  color: #555; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.writing-title {
  font-size: 2.5em; /* Larger font size for titles */
  margin-top: 20px; /* Add margin to move the title down */
}

.subtitle {
  font-size: 1.2em;
  margin-top: 10px;
  font-weight: normal; /* Ensure the subtitle is not bold */
}

.divider {
  width: 50%;
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ccc;
}

.published-date {
  font-size: 0.8em;
}

.writing-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px; /* Raise the poem text a bit higher */
  padding-bottom: 40px; /* Add padding to the bottom */
}

.writing-content.poems {
  justify-content: flex-start; /* Align poems to the top */
  margin-top: 0; /* Remove top margin for poems */
}

.writing-content.poems p {
  text-indent: 0; /* Remove indentation for poems */
}

.stanza {
  text-align: center;
  margin-bottom: 20px;
}

.poem-line {
  margin: 0;
  font-size: 1.2em; /* Increase the font size of the poem lines */
}

.writing-content p {
  text-indent: 2em; /* Indent the first line of each paragraph */
  margin-bottom: 20px; /* Add space between paragraphs */
}

.writing-references {
  margin-top: 20px;
}

.writing-references h2 {
  margin-bottom: 10px;
}

.writing-references ul {
  list-style-type: disc;
  padding-left: 20px;
}

.indent {
  text-indent: 2em; /* Indent the first line */
}

.quote {
  margin-left: 2em; /* Indent the entire block */
  font-style: italic; /* Optional: Italicize quotes */
}

.noIndent {
  text-indent: 0; /* No indentation */
}

.writing-detail-content p {
  margin-bottom: 1em; /* Add space between paragraphs */
}

.next-chapter-button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
  margin-bottom: 300px;
}

.next-chapter-button:hover {
  color: #555; /* Change color on hover */
  transform: scale(1.03); /* Slightly enlarge on hover */
}
