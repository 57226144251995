html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  height: 100%; /* Ensure body takes full height */
  min-width: 350px;
  min-height: 600px;
}

.about {
  animation: fadeIn 0.4s ease-in;
  width: 100%;
  height: 100vh; /* Ensure it takes full viewport height */
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Lexend, sans-serif;
}

.headerWrapper {
  background-color: rgba(18, 22, 25, 0.95); /* Darker grey with 90% opacity */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text */
  width: auto;
  margin: 0; /* Remove left margin */
}

.headerContainer p {
  padding: 0;
  font-size: 24px; /* Default font size */
  font-weight: lighter;
  color: rgb(255, 255, 255);
  margin-bottom: 10px; /* Reduce margin between paragraphs */
}

/* Media Queries for Different Screen Sizes */
/* 1700px to 1400px */
@media only screen and (min-width: 1400px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 48px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 20px;
  }
}

/* 1400px to 1100px */
@media only screen and (max-width: 1400px) and (min-width: 1100px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 18px;
  }
}

/* 1100px to 700px */
@media only screen and (max-width: 1100px) and (min-width: 700px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 30px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 14px;
  }
}

/* 700px and smaller */
@media only screen and (max-width: 700px) {
  .headerContainer h1 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    height: auto;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .headerContainer p {
    font-size: 10px;
  }
}
